

export default [
    {
        path: '/',
        name: 'home',
        component: () => import('./views/AllHomepage.vue')
    },
    {
        path: '/all-posts',
        name: 'posts',
        component: () => import('./views/AllPosts.vue')
    },
    {
        path: '/posts/:slug',
        name: 'show-post',
        component: () => import('./views/ShowPost.vue')
    },
    {
        path: '/tags',
        name: 'tags',
        component: () => import('./views/AllTags.vue')
    },
    {
        path: '/tags/:slug',
        name: 'show-tag',
        component: () => import('./views/ShowTag.vue')
    },
    {
        path: '/topics',
        name: 'topics',
        component: () => import('./views/AllTopics.vue')
    },
    {
        path: '/page/aku-mobile',
        name: 'aku-mobile',
        component: () => import('./views/PageAkuMobile.vue')
    },
    {
        path: '/page/karir',
        name: 'karir',
        component: () => import('./views/PageKarir.vue')
    },
    {
        path: '/page/kegiatan',
        name: 'kegiatan',
        component: () => import('./views/PageKegiatan.vue')
    },
    {
        path: '/page/fasilitas-fitur',
        name: 'fasilitas-fitur',
        component: () => import('./views/PageFasilitasFitur.vue')
    },
    {
        path: '/page/tata-kelola',
        name: 'tata-kelola',
        component: () => import('./views/PageTataKelola.vue')
    },
    {
        path: '/page/kinerja',
        name: 'kinerja',
        component: () => import('./views/PageKinerja.vue')
    },
    {
        path: '/page/tabungan',
        name: 'tabungan',
        component: () => import('./views/PageTabungan.vue')
    },
    {
        path: '/page/tabungan-aku',
        name: 'tabungan-aku',
        component: () => import('./views/PageTabunganAku.vue')
    },
    {
        path: '/page/tabungan-idul-adha',
        name: 'tabungan-idul-adha',
        component: () => import('./views/PageTabunganIdulAdha.vue')
    },
    {
        path: '/page/tabungan-umroh',
        name: 'tabungan-umroh',
        component: () => import('./views/PageTabunganUmroh.vue')
    },
    {
        path: '/page/deposito',
        name: 'deposito',
        component: () => import('./views/PageDeposito.vue')
    },
    {
        path: '/page/kredit',
        name: 'kredit',
        component: () => import('./views/PageKredit.vue')
    },
    {
        path: '/page/:slug',
        name: 'show-topic',
        component: () => import('./views/ShowTopic.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('./views/PageProfile.vue')
    },
    {
        path: '/:id',
        name: 'show-user',
        component: () => import('./views/ShowUser.vue')
    },
    {
        path: '*',
        name: 'catch-all',
        redirect: '/',
    },
];
