import NProgress from 'nprogress';
import Router from 'vue-router';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import base from './mixins/base';
import moment from 'moment';
import routes from './routes';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import id from "vee-validate/dist/locale/id.json";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import "vue-navigation-bar/dist/vue-navigation-bar.css";
import VueNavigationBar from "vue-navigation-bar";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import pdf from 'vue-pdf'
import VueSocialChat from 'vue-social-chat'

library.add(faInfoCircle);

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);
localize("id", id);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component("vue-navigation-bar", VueNavigationBar);
Vue.component("pdf", pdf);

require('bootstrap');

window.Popper = require('popper.js').default;

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

Vue.mixin(base);

Vue.use(VueSweetalert2)
Vue.use(VueMeta)
Vue.use(Router)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSocialChat)

NProgress.configure({
    showSpinner: false,
    easing: 'ease',
    speed: 300,
});

const router = new Router({
    base: '/',
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

new Vue({
    el: '#ui',
    router,
});
